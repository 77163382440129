<script setup lang="ts">

import PasswordlessRegistration from './components/PasswordlessRegistration.vue';

const route = useRoute()
const redirect = (route.query.redirect) as string
const inviteCode = (route.query.inviteCode) as string
const router = useRouter()
const civiqaUser = useCiviqaUser()

definePageMeta({
    layout: 'unauth',
})

watch(() => civiqaUser.value, (civiqaUser) => {
    if (civiqaUser && !civiqaUser.isFirebaseAnonymous) {
        console.log('redirecting to', redirect)
        router.push('/enviro/home')
    }
}, { immediate: true })

</script>

<template>
    <PasswordlessRegistration type="login" :redirect="redirect" :inviteCode="inviteCode" />
</template>
